<template>
  <v-container v-if="page" class="py-7">
    <div class="heading mb-7">{{ page.page_name }}</div>
    <section class="custom-content" v-html="page.content"></section>
    <template
      v-cloak
      v-if="isZoneDelivered && zoneDelivery && mapSettings.apiKey"
    >
      <YandexMap
        class="map"
        :settings="mapSettings"
        v-bind="mapAttributes"
        @map-was-initialized="mapReady"
      />
    </template>
  </v-container>
</template>

<script>
import { yandexMap as YandexMap } from 'vue-yandex-maps'

export default {
  map: null,

  components: {
    YandexMap,
  },

  data() {
    return {
      page: null,
      city: this.$store.getters['app/city'],
      mapAttributes: {
        coords: [61.698657, 99.505405],
        bounds: null,
        zoom: 3,
        controls: ['fullscreenControl', 'zoomControl'],
        options: { suppressMapOpenBlock: true },
      },
    }
  },

  computed: {
    id() {
      return this.$route.params.id
    },

    mapSettings() {
      return {
        apiKey:
          this.$store.state.app.settings.merchant_yandex_api_key ||
          'a2fa5455-61f5-4768-af06-1cdc61567222',
      }
    },

    isZoneDelivered() {
      return this.page?.delivery_zones_map_to_page || false
    },

    zoneDelivery() {
      return this.page?.delivery_zones_kml_url || false
    },
  },

  watch: {
    $route() {
      this.loadPage()
    },
  },

  methods: {
    async loadPage() {
      try {
        const { commit, dispatch } = this.$store
        commit('overlay/update', { active: true })
        this.page = await dispatch('app/loadPage', this.id)
        commit('overlay/update', { active: false })
      } catch (e) {
        this.page = null
      }
    },

    mapReady(map) {
      this.map = map
      window.ymaps.geoXml.load(this.zoneDelivery).then((res) => {
        res.geoObjects.each((item) => {
          if (item.properties.get('metaDataProperty')) {
            item.options.set(
              'fillColor',
              item.properties.get('metaDataProperty').fillColor
            )
            item.options.set(
              'fillOpacity',
              item.properties.get('metaDataProperty').fillOpacity
            )
            item.options.set(
              'strokeColor',
              item.properties.get('metaDataProperty').strokeColor
            )
            item.options.set(
              'strokeOpacity',
              item.properties.get('metaDataProperty').strokeOpacity
            )
            item.options.set(
              'strokeWidth',
              item.properties.get('metaDataProperty').strokeWidth
            )
          }

          item.balloon?.destroy()
          item.properties.set(
            'hintContent',
            item.properties.get('metaDataProperty').outerName ||
              item.properties.get('metaDataProperty').name
          )

          item.events.add('click', this.onMapClick)
        })
        this.map.geoObjects.add(res.geoObjects)
        this.map.setBounds(res.geoObjects.getBounds())
      })
    },
  },

  created() {
    this.loadPage()
  },
}
</script>

<style lang="scss">
.custom-content {
  font-family: $body-font-family !important;

  > * {
    font-family: inherit !important;

    > * {
      font-family: inherit !important;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    max-width: 100%;
  }

  table {
    max-width: 100%;
    width: auto !important;
  }

  img {
    max-width: 100%;
  }

  iframe {
    max-width: 100%;
    max-height: 240px;
  }
}

.map {
  width: 100%;
  height: 240px;
}
</style>
